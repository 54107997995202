import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  initialize() {
    this.showTab()
  }

  change( event ) {
    this.index = this.tabTargets.indexOf( event.currentTarget )
  }

  showTab() {
    this.tabTargets.forEach( ( tab, index ) => {
      const panel = this.panelTargets[index]
      tab.classList.toggle( "is-active", index == this.index )
      panel.classList.toggle( "is-hidden", index != this.index )
    } )
  }

  get index() {
    return parseInt( this.data.get( "index" ) || 0 )
  }

  set index( value ) {
    this.data.set( "index", value )
    this.showTab()
  }
}

// they're in order. 
// combine with a 'lazy loaded frame' for remote stuff... https://turbo.hotwired.dev/reference/frames target="_top"
// might need to review my CSS https://jsfiddle.net/qwvurt0p/2/ 
// https://cdnjs.cloudflare.com/ajax/libs/bulma/0.6.2/css/bulma.css
// https://bulma.io/documentation/components/tabs/
//<div data-controller="tabs">
//  <div class="tabs is-boxed is-marginless">
//    <ul>
//      <li data-target="tabs.tab" data-action="click->tabs#change">
//        <a>Tab 1</a>
//      </li>
//      <li data-target="tabs.tab" data-action="click->tabs#change">
//        <a>Tab 2</a>
//      </li>
//      <li data-target="tabs.tab" data-action="click->tabs#change">
//        <a>Tab 3</a>
//      </li>
//    </ul>
//  </div>
//
//  <div class="tab box is-hidden" data-target="tabs.panel">
//    Tab panel 1
//  </div>
//
//  <div class="tab box is-hidden" data-target="tabs.panel">
//    Tab panel 2
//  </div>
//
//  <div class="tab box is-hidden" data-target="tabs.panel">
//    <iframe width="560" height="315" src="https://www.youtube.com/embed/y3niFzo5VLI" frameborder="0" allow="autoplay; encrypted-media" //allowfullscreen></iframe>
//  </div>
//</div>