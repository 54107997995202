import { Controller } from "@hotwired/stimulus"

// still a bug the first page moving the scroll postion even though the second one doesn't... no idea
// what if we add hidden element then navigate back to it? sounds specific to the container we're in.
// this still feels overcomplex, tempted to replace with inline lazy-loading frames
export default class extends Controller {
  static values = { url: String, prepend: Boolean, container: String }
  static targets = ["bottom"] // "container", 

  // re-called for convo each page unfortunately, but oh well.
  connect() {
    // console.log( "infinite connect", this.urlValue, this.containerValue )
    if ( localStorage['debug'] ) window.debug = 1
    this.next_page = 2
    this.disabled = false

    this.setup_observer()
    // bottomTarget isn't defined until after this?
    // if ( this.hasBottomTarget ) {
    this.observer.unobserve( this.bottomTarget ) // make sure we don't watch twice
    this.observer.observe( this.bottomTarget )
  }

  next() {
    this.observer.unobserve( this.bottomTarget )
    // get the page, it returns a turbo response that automatically adds it to our element
    // we rely on turbo to insert into the right container
    bring( 'get', `${this.urlValue}?${this.prependValue ? 'prepend=1&' : ''}container=${this.containerValue}&page=${this.next_page}` ).then( async ( res ) => {
      let text = await res.text
      // if ( window.debug )
      console.log( `loaded page ${this.next_page} for ${this.containerValue}, length ${text.length} from ${this.urlValue}` )
      this.next_page += 1

      setTimeout( () => { window.read_more(); this.dispatch( "next" ) }, 20 ) // callbacks for other controllers. why setTimeout?
      if ( text.length < 120 ) return this.disconnect() // too short = no results
      this.disabled = false
      // $( '.msg_scroll' ).css( 'overflow', 'hidden' ) // try disabling, no didn't help. UGH

      // this needs to be *really* long for the dom to finish updating I think?
      setTimeout( () => {
        // console.log( 'observing again' )
        this.observer.observe( this.bottomTarget )
        // $( '.msg_scroll' ).css( 'overflow', null )
      }, 1700 )
    } )
  }

  // each one needs its own observer
  setup_observer() {
    // console.log( "setting up", this.observer )
    this.observer ||= new IntersectionObserver( ( [entry] ) => {
      // console.log( "Triggered", this.urlValue, this.disabled, entry.isIntersecting )
      if ( this.disabled || !entry.isIntersecting ) return;
      this.next()
    } )
  }

  // disconnects and reconnects the controller each time we change pages evidently? Scrolling still works at least...
  disconnect() {
    // if ( window.debug )
    // console.log( "infinite disconnect", this.urlValue );
    this.observer.disconnect()
    if ( this.hasBottomTarget ) this.bottomTarget.innerHTML = '<hr />'
  }
}