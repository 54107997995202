// might NOT need this?  Just redirect /messages & call it a day? then could add this or rewrite when we ditch the rest of the code.

// NOT a reusable controller, will only ever be one, but seems like the right way to organize the code?
// could manage other modals, though we don't have/need any right now...
import { Controller } from "@hotwired/stimulus"

// might be worth having a 'msg' controller & a 'convo' controller (with a specific person)
// so it'll have a href='/convo/' aria-label="Open messages page" data-controller='convo_window' data-action="convo_window#showhide:prevent" [msg]
// blind folks would prob pref it to NOT use javascript..

// what do we need to do for preview showing?
// can it be a abs-positioned element max-height that's always 'there'?  Then when the stream updates the most recent is visible
// need to 1) Remove it on scroll - could set a listener in connect()
// 2) remove it on click.  3) If not clicked 'x', open messages & take us to that?
// if msgs is open, don't show it?

// can be abs-positioned INSIDE 
// tricky to manage msgwindow from multiple places.. maybe makes most sense to keep state in DOM & each can toggle?
// we don't want it to show if msgWindow is open though, ugh.
// adding listeners for click at connect() still seems like the least-bad solution to me.

// can we only call controllers we're inside? I don't think so.

// msg-window should have data-turbo-permanent=1 so it stays..
// can I replace the preview for ALL people with the turbo one?
export default class extends Controller {

  connect() {
    this.add_events()
  }

  add_events() {
    // hide preview on scroll - leaving out for now, window.scrolls in actions.coffee
    // it actually calls $('.messages_link:first').click() right now, so if that's this, then good?

    // swipe from right side to open messages

    // clicking overlay closes msg-window

    // clicking preview opens window & scrolls to top
  }

  showhide() {
    // opening it adds msg-open to body tag -> this toggles whether overlay is visible & whether preview shows?

    // show msg-window, load content into it if it's empty

    // show overlay
    q( this.eleValue ).classList.toggle( 'hidden' )
  }
}