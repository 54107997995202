# if ( touchendX > touchstartX && ( touchendX - touchstartX ) > distance ) {      } //right
# want to work on big browsers too, ugh.
# need to start having the 'messages' page always loaded, at least some.
# while dragging text selectgion -> document.onselectstart = function(){ return false; } or preventdefault on touchstart

window.register_swipe = ->
  return if window.added_swipe
  window.added_swipe = 1
  # console.log("register_swipe")
  # for now, adding unread # from cookies here
  $('.update_num').html $.cookie('unr')
  
  delta = if window.innerWidth < 450 then 150 else 250
  touchstartX = 0
  w = null
  in_msg_window = false
  return if !document.getElementById('msg-window') # join page

  # ooh, want swipe-right to work inside the convo window to close it... console.log("skipping")
  start = (e) ->
    return if window.location.href.match(/\/convo/) or (e.touches and e.touches.length > 1) or e.target.closest('textarea, input, .cke') or e.ctrlKey or e.shiftKey or !user.id
    return if e.target.closest('.no_drag')
    return if !e.touches and e.target.closest('.poem_body, .sub.solo, .no_drag') # touches tap+hold to select

    if w = document.getElementById('msg-window')
      touchstartX = if e.touches then e.changedTouches[0].clientX else e.clientX      
      document.addEventListener 'mousemove', move, {passive: true} if !e.touches
      document.addEventListener 'touchmove', move, {passive: true} if e.touches
      return in_msg_window = true if e.target.id == 'msg-window' or e.target.closest('#msg-window') #start it still
      w.style.right = '-9999px'
    true
  
  # want to start loading turbo at a certain point. ugh, calls it too much still. Pretty fast so not debouncing.
  # still sometimes gets stuck 'on' but I'd had to disable stopping when e.buttons != 1 due to safari...
  move = (e) ->
    # console.log("move with ", touchstartX, e.buttons, e.buttons == 0)
    return rem_listen(true) if !touchstartX # safari bug gives e.buttons=0 move just before end. or e.buttons == 0 #and e.buttons != 1 # catch when it gets stuck 'on'
    x = if e.changedTouches then e.changedTouches[0].clientX else e.clientX
    diff = Math.abs(touchstartX - x)
    return if diff < 45 or diff > 760

    if in_msg_window and touchstartX < x
      w.style.right = 0 - (x - touchstartX)+'px'

    else if !in_msg_window and touchstartX > x
      msg_open_actual() if diff > 125
      w.style.right = -w.offsetWidth + (touchstartX - x)+'px'

  # sometimes touchstartX is 0 here when we end with a selection?
  end = (e) ->
    return if !touchstartX
    x = if e.changedTouches then e.changedTouches[0].clientX else e.clientX

    if in_msg_window and x - touchstartX > delta
      q('.close_window').click() # move to jsController
      window.getSelection().removeAllRanges()

    else if in_msg_window
      w.style.right = 0

    else if touchstartX - x > delta
      w.style.right = 0
      window.getSelection().removeAllRanges()
      msg_open()
      
    else
      msg_close()
    rem_listen()

  rem_listen = (reset) ->
    touchstartX = in_msg_window = 0
    document.removeEventListener 'mousemove', move
    document.removeEventListener 'touchmove', move
    msg_close() if reset

  window.msg_open_move = move # need reference to cancel it in sortable
  document.addEventListener 'mousedown', start
  document.addEventListener 'mouseup', end, {passive: true}
  document.addEventListener 'touchstart', start
  document.addEventListener 'touchend', end, {passive: true}

  # STRIPE add2 init 4242424242424242
# wow this stripe got ugly in a hurry
# strange problem with @method vs. window.method now?
window.stripe_load = ->
  # aah is window.X maintained per page?  It should be.  So 
  if window.stripe
    if $('#st-num').length then stripe_add()
    if $('#card-element').length then stripe_subscribe() 
    return 

  console.log('attaching stripe script')
  sc = document.createElement('script');
  sc.type='text/javascript'
  sc.src='https://js.stripe.com/v3/'
  sc.async=true
  sc.onload = -> 
    if window.stripe
      console.log("In onload for 2nd time, skipping")
    else 
      # want to load it every page, even if we don't create an element.  Vs. downloading it each time but never creating window.stripe; oops...
      # console.log('stripe onload')
      # if $('#st-num, #card-element').length
      key = if window.location.host.match(/localhost|next:3|ngrok/i) then "pk_test_0eSC8ebOYGujy9kfAizOXHwp" else "pk_live_h4iY92SkyAKqcPARqenyzkr2"
      window.stripe = Stripe(key)
      # wow this is being a REALLY BIG pain in the ass...
      if $('#st-num').length then stripe_add()
      if $('#card-element').length then stripe_subscribe() 
        
  $('body').append sc


# window.stripe_new = ->
  # on submit, create a payment intent object & pass intent.client_secret back to be rendered inside the elements form.
  # https://stripe.com/docs/payments/save-during-payment
  # how to handle customization options during the checkout process since this will change the amount charged?
  # I think the idea is that it'll get a new intent each time you change options
  # load a new payment intent field when we change tabs..
  

window.stripe_add = ->
  #initialize stripe, mount card & payment request & listeners
  # what problem caused us to add this?  Now fails to initialize for return-to-turbolinks pages. expanded store -> subscribe -> expanded store.
  # return if window.stripe2_already
  # window.stripe2_already = 1

  # create elements - does 'mount' return the element hopefully?  TEST
  # mount doesn't return anything
  elements = stripe.elements()
  window.card = elements.create('cardNumber', placeholder: $('#st-num').data('placeholder'))
  cvv = elements.create('cardCvc')
  exp = elements.create('cardExpiry')
  zip = elements.create('postalCode')

  # could inline this after 'mount' as well if that returns the element
  [card, cvv, exp, zip].map (a) ->
    a.addEventListener 'change', (event) ->
      console.log event.error.message if event.error
      return if window.hide_change
      $('#card-errors').html ''
      $('#card-errors').html( "<div class='error'>#{event.error.message}</div>" ) if event.error

  cvv.mount('#st-cvv')
  exp.mount('#st-exp')
  card.mount('#st-num')
  zip.mount('#st-zip')
  
  # Create a token or display an error when the form is submitted.
  window.form = $('#billform')
  console.log("adding form listener")

  form.get(0).addEventListener 'submit', stripe_submit

# we use stripe elements to convert card numbers to a 'token'.
# this token is then used server-side to process the payment.
# we also implement the payment Intent API - would it be possible to replace everything with that?
# have to do some stuff to hide the error listeners for users using their 'card on file'.  maybe there was a better way to show a card selection with a checkbox, but I like this better and the whole idea was keeping the same as before
window.stripe_submit = (event) ->
  console.log "stripe submit"
  # for the sometimes-there payment form @ shared, use the default submit if
  if $('.payment_area').is(':hidden') or $('.card_area').is(':hidden')
    $('#stoken').val("card on file")
    return(console.log "Skipping stripe b/c hidden shared payment form");

  event.preventDefault()

  # hide errors when we leave the cc field blank to 'use card on file'
  window.hide_change = 1
  delay 6000, ->
    window.hide_change = 0
    window.recent_form.find('input[type=submit]').removeAttr 'disabled'

  stripe.createToken(card).then (result) ->
    window.result = result
    if err = result.error
      plc = $('#st-num').data('placeholder')

      # allow 'use card on file'
      if plc and plc.match(/card ending in/) and result.error.message.match /card number is incomplete/ 
        $('.StripeElement--invalid').removeClass('StripeElement--invalid')
        $('#stoken').val 'card on file'
        return stripe_actual_submit() 

      # allow alt-pay='paypal'.  ah-hah!  wasn't triggering for me b/c mine had 'card ending in' in the field...
      if $('#alt_pay').val()
        console.log("allowing submit b/c alt_pay")
        $('.StripeElement--invalid').removeClass('StripeElement--invalid')
        return stripe_actual_submit() 

      window.hide_change = false
        
      console.log("payment error", result.error) 
      $('#card-errors').html "<div class='error'>#{result.error.message}</div>"
      window.recent_form.find('input[type=submit]').removeAttr('disabled')
    else
      console.log "success", result
      $('#stoken').val result.token.id
      stripe_actual_submit()

# using card on file or something, send the form to our server and we'll process on the server-side
# still shows the errors for a flash & marks them red.
window.stripe_actual_submit = ->
  console.log "stripe_actual_submit submit"
  $('#card-errors').hide()
  $('#len_choice').val $('#len_select .active').text()
  $('#billform').get(0).removeEventListener 'submit', stripe_submit
  $('#billform').submit()
  window.recent_form = form
  window.hide_change = 0
  

window.stripe_subscribe = ->
  #initialize stripe, mount card & payment request & listeners
  # return if window.stripe_already
  # window.stripe_already = 1
  elements = stripe.elements()
  window.card = elements.create('card')
  card.mount '#card-element'
  window.form = $('#billform')

  form.get(0).addEventListener 'submit', stripe_submit
  card.addEventListener 'change', (event) ->
    $('#card-errors').html ''
    $('#card-errors').html( "<div class='error'>#{event.error.message}</div>" ) if event.error
  
  # Create a token or display an error when the form is submitted.
  return if user.name != 'kevin'
  
  # https://stripe.com/docs/stripe-js/elements/payment-request-button
  # start with a dummy request to check if paymentrequest is available, we'll update with our intent later
  # or just use the global PaymentRequest.canMakePayment() 
  window.paymentRequest = stripe.paymentRequest(
    country: 'US'
    currency: 'usd'
    total:
      label: "Silver subscription monthly"
      amount: 6*100
    requestPayerName: true
    requestPayerEmail: true)

  window.prButton = elements.create('paymentRequestButton', paymentRequest: paymentRequest)

  # Check the availability of the Payment Request API first.
  paymentRequest.canMakePayment().then (result) ->
    if result
      $('#my_payment_request').show()
      if result.apple
        $('$my_payment_request span').text("Apple Pay")

  # I believe this will disable itself automatically
  $('#my_payment_request').on "click", () -> 
    amt = parseInt $('.cost:visible').last().text().replace(/^\$/,'')
    len = if $('#len_select .active').text().match /once/i then 'lifetime' else 'monthly'
    plan = $('#splan').val()

    $.post '/store/subscribe_intent', {amt: amt, len: len, plan: plan}, (response) =>
      console.log('payment intent returned', response)
      window.clientSecret = response.secret

      paymentRequest.update(
        total:
          label: response.label, 
          amount: response.amt*100)
      paymentRequest.show()

  # in coffeescript 'async' is automatic when there's an 'await' inside it
  # temorarily disabling to try and figure out why I'm getting a syntax error with transition from sprockets to importmaps
  # paymentRequest.on 'paymentmethod', (ev) ->
  #   console.log "paymentmethod, got ev", ev

  #   # OMG I hate destructed assignments fuckery {a, b: c} = {a: 'test', b: {c: 'asdf'}}
  #   # const {paymentIntent, error: confirmError} 
  #   # this could be used iwth data from a card element directly via payment_method: {card: cardElement, billing_details: {zip:12121 }}
  #   res = await stripe.confirmCardPayment(clientSecret, {payment_method: ev.paymentMethod.id}, {handleActions: false});

  #   if res.error
  #     console.log('error', res.error)
  #     ev.complete('fail')
  #     $('#intent_result').html("<div class='error'>"+res.error+"</div>")

  #   else
  #     console.log('success, ev=', ev)
  #     ev.complete('success');

  #     if (res.paymentIntent.status == "requires_action")
  #       #Let Stripe.js handle the rest of the payment flow, 3d auth/etc via popup
  #       confirm_res = await stripe.confirmCardPayment(clientSecret);
  #       console.log("stripe confirm res", confirm_res)

  #       if confirm_res.error
  #         $('#intent_result').html("<div class='error'>"+confirm_res.error+"</div>")
  #       else
  #         console.log('success after card confirmation action')
  #         post_payment_id_show_success(res.paymentIntent.id)
        
  #     else 
  #       # no fucking token, they've fucking added webhooks now!  UGH I HATE THIS FUCKING SYSTEM YOU IDIOTS.  Probably simpler with 3d secure vs. a bounce url?
  #       post_payment_id_show_success(res.paymentIntent.id)

  # or maybe we can save it via the webhook?
  # I don't think this step is actually necessary?  Could add to the billing note I guess...
  post_payment_id_show_success = (id) ->
    $.post '/store/save_intent_payment', {id: id}, (response) =>
      console.log('saved intent payment', response)
      $('#intent_result').html("<div class='notice'>Payment successful, thank you!  It will be credited shortly.</div>")

String.prototype.live = (method, func) ->
  # console.log "string live for #{this}"
  if !func then func = method;method = 'click' # allow use w/o method, default to click
  $(document).off(method, this.toString()).on method, this.toString(), func

'#show_author'.live -> 
  cur = parseInt( $.cookie('hide_a') ) || 0
  $.cookie('hide_a', (if cur > 0 then null else 1), 999)
  if cur > 0 then $('.bio, .copy_name').show() else hide_contest_bio()
  true


# only run for certain users?  or randomly?  Hmm...
window.check_missing_images = ->
  return if window.navigator.userAgent.indexOf("MSIE ");
  return unless user.id == 1
  delay 500, ->
    $('.u img').each ->
      console.log "test", @.src, @.naturalWidth
      # return if @.src =~ /\/Image\// # cant remomve these from poem body anyways

      if !@.complete or (typeof @.naturalWidth != "undefined" and @.naturalWidth == 0)
        console.log "missing image", @.src
        $(@).css 'visibility', 'hidden'
        $.post("/image/missing", {url: @.src})

window.hide_contest_bio = ->
  $('.bio, .copy_name').hide() if parseInt($.cookie('hide_a')) and $('body.contestshow').length

# after 1.5s, remove any .adsbygoogle that have no children...
# remove_empty_ads = ->

# flexbox sizes to height, so get last element inside it and check its height+position
# is there a place we want this on .side_bar and not just .rcol?
window.center_poem_list = ->
  return if $(window).width() < 700 or $('body').is('.right_comment')
  
  # how slow is this ':visible' selector?
  p = $('.side_bar').children(':visible').last()  
  b = $('body')
  return if !p.length and !b.is('.scrolled_down')  
  window.pick_height = p.position().top+p.height() if !b.is('.scrolled_down')

  # WTF how did this ever work... this is static people....  new site had rcol being height 100% stills omehow
  # return(console.log "<10 diff", window.pick_height, newh) if Math.abs(window.pick_height - newh) < 10 # only trigger every 6px?
  # window.pick_height = newh if new_height # only update+save pick_height while we can see it
  
  # zepto broke document.body.scrollTop()
  # hmm, and its always returning 0 now document.documentElement.scrollTop
  if document.documentElement.scrollTop > pick_height and !b.is('.scrolled_down') #p.is(':visible') # hide it
    # console.log 'hide'
    $('body').addClass('scrolled_down')
    
  else if document.documentElement.scrollTop < pick_height and b.is('.scrolled_down') # show it
    # console.log 'show'  
    $('body').removeClass('scrolled_down')

window.fix_commented2 = (comments) ->
  $.each comments, (_, v) ->
    e = $(".i_#{v[0]} textarea")
    a = e.attr('placeholder') || ''
    e.attr('placeholder', a.replace(/^Comment -/, "You said - #{v[1].replace(/<(p|br).*?>/g,'\n').replace(/&nbsp;/g, ' ').replace(/Bonus comment for being a paid member|\n|<(?:.|\n)*?>/gm, ' ')}"))
    


# Turbolinks won't be loaded if we're a free member or guest
window.redirect_to = (url, no_warning) ->
  console.log "redirect_to", url, no_warning
  throw "Invalid url sent" if !url or url == ''

  if Turbo? and !url.match(/image\/show/)
    if window.dbg
      console.log "using Turbo.visit", url
      
    if !no_warning and $('form.dirty').length
      console.log "no_warning: #{no_warning} so confirming"
      if confirm("Are you sure you want to leave without submitting your changes?")
        Turbo.visit url
    else
      console.log "visit without warning"
      Turbo.visit url
  else
    console.log "redirect no turbo"
    document.location = url


window.delay = (ms, func) -> setTimeout func, ms 

window.after_ajax = (base) ->
  read_more()
  topic_comment_width() if $('body').is('.topicshow')
  $('ins').hide() if user.plan != 'free' and user.id != 1 # .adsbygoogle, avoid spaces?
  $(".ou.u_#{user.id}").show()
  $('.ou:not(.nomod)').show() if user.a
  $('.wait_gif').remove()
  profanity $(base).get(0) if user.name != 'Guest' and !user.okaa
  hide_contest_bio()
  already_commented_icon() if $.cookie('jt') == '1' and $('.itm').length
  hide_image_errors()
  lazy_iframe()
  trade_min_length(210) if location.href.match(/\/trade\?critique/)
  trade_min_length(75) if location.href.match(/\/trade/)
  if $.cookie('ai')
    $('.ai_analysis_show').show()
    $('.ai_analysis').hide()

# shouldn't this only happen when we click on it?
window.lazy_iframe = ->
  $('iframe[data-src]').each (n, x) -> x.src = x.getAttribute('data-src')

window.inf_scroll = ->
  $('.inf').each ->
    inf = $(@)
    return if inf.data('pending') or inf.css('display') == 'none' or inf.parent().css('display') == 'none' # ourselves or tab containing
    
    if (window.pageYOffset + window.innerHeight) > (inf.offset().top + inf.height())
      # console.log "#{  (window.pageYOffset + window.innerHeight)   } vs #{  (inf.offset().top + inf.height())  }"
  
      inf.data 'pending', 1    
      _get_next_inf( inf )

# always starts simple and gets really freaking complicated, sigh
window._get_next_inf = (inf) ->
  return if !inf.is(':visible')

  # fixing a chrome bug, if no attr('p'), move data('p') into it
  inf.attr('p', inf.data('p')) if !inf.attr('p')

  # console.log "get_next_inf", inf.get(0), inf.attr('p')
  p = parseInt( inf.attr 'p' ); p = 0 unless p > 0 # NaN
  inf.append("<center>#{wait_gif}</center>")
  inf.attr('p', p+1) 
      
  $.get "#{ inf.attr('rel') }page=#{ inf.attr('p') }", (data) ->
    res = inf.append data
    # console.log "appended", res
    res.find('.ays').areYouSure() # set AYS

    # console.log "appending data", data, data.match(/data-id/)
    if !data.match(/data-id/) # if no results returned?  
      console.log "didn't see data-id object, stopping inf"
      return $('.inf').removeClass('inf') 

    inf.find('.inf').removeClass('inf') # fix loops inside loops
    _max_inf()
    after_ajax '.inf'
    remove_dupes()
    inf.data 'pending', ''

# wtf did I do with 'none' class? .removeClass('none')
window.set_unread_num = (unread) ->
  # console.log "unread: #{unread}"
  $('.update_num').html unread
  # $('.update_num').addClass 'none' if parseInt(unread) == 0
  
window._max_inf = ->
  while $('.inf > .sub').length > 100
    # console.log "removing top inf element"
    $('.inf > .sub').first().replaceWith(' ~removed since you loaded more pages') #remove()
    
      
# worth it?  I think it's hiding an underlying problem...  very nice for the "newest" page
window.remove_dupes = ->
  $('.sub:visible').each ->
    cid = $(@).data('id') #attr('class').match(/_(\d+)/)[0]
    # console.log "checking for dup item_#{cid}"
    a = $(".i_#{cid}:visible")[1] #was :eq(1)
    if a
      console.log "hiding duplicate #{cid} #{$(a).find('h1').text().trim()}", a
      a.remove()
      
window.fix_cats = ->
  # unless name is "gradsense"
  $(".cats a, .cats_dot a").each (num,i) ->
    $(i).text $(i).text().replace(/^(Poems|Stories|Contest) (in|about|for|on)| (poems|stories|storys)$| (poems|stories) contest$| (poems|stories) group$| book|writing group$/i, "")  
  
window.scroll_to = (selector) ->
  console.log("scroll_to")
  # $('html, body').animate({   scrollTop: $(selector).offset().top   }, 500);
  document.documentElement.scrollTop = $(selector).offset().top  

# inView = (elem) ->
#   elem = $(elem).get(0) if typeof(elem) == "string"
#   return false unless elem
#
#   # log elem
#   docViewTop = $(window).scrollTop()
#   docViewBottom = docViewTop + $(window).height()
#   elemTop = $(elem).offset().top
#   elemBottom = elemTop + $(elem).height()
#   (elemBottom >= docViewTop) and (elemTop <= docViewBottom)


window.group_scroll = ->
  $('.group_chat_inner').each ->
    $(@).scrollTop $(@).prop('scrollHeight')
  false
  
      
window.fix_likeds = (likes)->
  $.each likes, (k, v) ->
    # fix number?  Hmm., longrank includes old 'likes' currently.  not sure how to handle that
    # $('.')
    # fix names
    # console.log "k: #{k}, v: #{v}"
    $(".i_#{k} .lk").attr('alt', "Liked: #{v.join(', ')}")
    
    # odd bug with # lieks showing < the list of people... so check it and increase if ours is greater
    num = parseInt $(".item_#{k} .act_like .num").text()    
    $(".item_#{k} .act_like .num").text(v.length) if num < v.length

    # console.log "num: #{num} vs #{v.length} .item_#{k} .act_like .num"
    
  
window.no_paste = ->
  $("textarea").bind 'paste', (e) ->
    return true if window.ok_paste
    return true if $(e.target).parents('form').is("#comment-quick,#comment-reply")
    window.e = e
    $(e.target).before "<div class='notice copywarn'>Sorry, copy+paste is disabled here.</div>" unless $('.copywarn').length
    false
    
# example height = 276 x 113
window.load_tip = ->
  # removed this... return if /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

  $('body').popover
    selector: '.tip'
    content: -> $(this).attr('alt')
    html: true
    placement: (context, src) ->
      return $(src).data('placement') if $(src).data('placement')
      return "bottom"
      
    trigger: 'hover'
    delay: 130
    viewport: { "selector": "body", "padding": 10 }

window.fix_noguest = ->
  return if window.name == 'Guest'
  
  $('.nog').each (n, ele) ->
    e = $(ele)
    e.find('h3 a, .heading a').html e.data('title')
    e.find('.brief').html e.data('brief')
  
window.msg_window = (obj, name) ->
  box = "<div class='say_form clearfix my1' method='post' data-to=\"#{name}\"><textarea name=\"body_#{name}\" rows=1 placeholder=\"Your message to #{name.replace(/_/g,' ')}..\"></textarea>" + "<span class='pull-right pull-margin'> <a class='history_link' href=\"/messages/history/#{name}\" tabindex='-1'>History</a></span> <a href='#' type='submit' class='btn notp btn-fill'>Send to #{name.replace(/_/g,' ')}</div>"

  # top = t.parents('li').find('.body') if t.parents('.info').length # where's this one? favs?  dont think so
  top = obj.parents('.contain, .by').first()
  top = obj.parent() if !top.length
  top.first().append( box ).find('.say_form textarea').first().focus()
  # $('.dropdown-menu').hide() # can we just hide all of them?
  # scrollTo 0, $(document).scrollTop()+30 # whats this do? 
  false  

# fixes google ads hiding the sides of topics
window.topic_comment_width = ->
  return unless $('.topics-in').length
  h = $('.topics-in').height() + $('.topics-in').offset().top
  $('section').css('min-height', "#{h- 250}px")
  $('.comments .media').slice(0, 20).each (idx, k) ->
    if $(@).position().top < h
      $(@).addClass('atf')
      # console.log "actually setting atf", "#{h+185}px", $(@).css('max-width'), $(@)

# ###############################  READ MORE  #########################################
window.read_more = ->
  $('.preview').not('.open').not('.closed').each ->
    h = $(@).height()
    $(@).addClass 'closed'
    newh = $(@).height()
    
    if newh+39 > h
      $(@).removeClass('preview').removeClass('closed').next('.read_more_link').remove()

'.read_more_link'.live ->    
  v = $(@).hide().parent().find('.preview').toggleClass('closed open') # what uses this?  none.removeClass('closed').addClass 'open'
  v.css('overflow','auto').css('max-height', $(window).height()-100) if v.height() > 1300
  $(@).show().text(if $(@).parent().find('.preview').hasClass('closed') then "Read more →" else "Read less") if $(@).hasClass 'read_toggle'
  false
  
$.fn.disable = (val) ->
   @.attr('disabled', 'disabled');
   
$.fn.enable = (val) ->
  console.log('fn.enable')
  @.removeAttr('disabled')

# try setting html5 minimums, I think that'll prevent submission too. Oops!  was affecting the messages area lol.
window.trade_min_length = (siz) ->
  $ ->
    $(".comment-form textarea").attr('minlength', siz)
    window.trade_min = siz
  
# window.trade_count = ->
#   clearTimeout(trade_timer) if window.trade_timer
  
#   if !$(@).parents('.msg').length
#     trade_timer = delay 125, =>
#       par = $(@).parents('form') #parent().parent().parent() #$(@).parents('.subitem')
#       len = trade_sum_with_wizard par # sum with wizard bonuses
#       if len > trade_min
#         par.find('input.btn').enable().val "Submit"
#       else
#         # log "updated it", par.get(0), par.find('input.btn').length, par.length
#         par.find('input.btn').disable().val "Min = #{trade_min-len} more letters"

# window.trade_sum_with_wizard = (e) ->
#   # .replace(/(.)\1{2,}/g, '$1') - no repeat chars at all 
#   sum = $("textarea", e).val().replace(/\s+/g,'').replace(/(.)(\1){2,}/g, '$1$2').length
#   sum += 5 if $('.btn-group input:checked', e).length
#   sum
  
#
###############   COMMENTED ALREADY TRACKING   ###################
# leaving this for now for people who are primarily using the "list" mode...
# lets see if anyone notices me removing this
window.already_commented_icon = ->
  try
    $('.itm').each (n, item) ->
      return if $(item).hasClass("u_"+user.id) 
      if localStorage[ "c_"+ $(item).data('i') ] != undefined
        $(item).find(".fa-comment").before("  <span class='c_already' title='Commented already'>&#x2713;</span>") # checkmark?


window.commented_record = ->
  try
    localStorage["c_#{window.itm.id}"]='' if window.itm and itm.kind and itm.kind.match /poem|story/i

# SORTING
window.load_sortable = ->    
  console.log "loading sortable"
  new Sortable $('.sortable').get(0), {onUpdate: saveOrder}

# stores the updated list from 'data' + calls contest_update
window.saveOrder = ->
  data = $(".sortable > .itm").map ->
    $(this).data 'i'
  $("#order").val data.get().join(",")
  contest_update() if location.pathname.match /contest/

  # push updates to website instantly.  list is /list/edit/#
  if location.pathname.match /contest/
    $.post '/contest/reorder', {id: $('#i').data('id'), ordr: $("#order").val()}, (res) =>
      console.log("saved order, response", res)

  if location.pathname.match /list\/edit/
    $.post '/list/edit', {id: $('#i').data('id'), order: $("#order").val()}, (res) =>
      console.log("saved order, response", res)

# add trophy icon to the 1-3 + HMs
window.contest_update = ->
  $(".sortable > .itm").each (idx, e) ->
    name=contest_tr idx
    # console.log "idx, name, ele", idx, name, e
    $(e).find('.sprite').attr 'class', "sprite tr-#{name}"

# honorable should be defined on judge page
window.contest_tr = (num) ->
  switch num
    when 0 then return "gold"
    when 1 then return "silver"
    when 2 then return "bronze"
  
  # if honorable is 1, id= 3 so num will be == 2
  if num <= 2+honorable then "honorable"

# moving inline, has to run before scripts!
# window.fix_house_ad = (ad_name) ->
#   # log "got fix house for '#{ad_name}'"
#   $( document.getElementById(ad_name) ).remove() #replaceWith "<p>#{  adblock_ad() }</p>"

# js isn't working well with the 90k text file loaded :(  Try preparing into JS, see how that goes?
window.word_init = ->
  window.g = {}; window.b = {}; is_g = true

  f = $.get "/javascripts/words.txt", (data) ->
    for w in data.split(/\n/)
      is_g = false if w == '---'
      if is_g then g[w]=true else b[w]=true
      null
      
window.wordrank = ->
  word_init if !window.g
  good = []; bad = []
  
  # hard to re-stem, but ignore that for now?
  $("#item_rendered").val().split(/\s+/).map(stem).each (word) ->
    good.push w if g[w]
    bad.push w if b[w]    
  
  console.log(good, bad)

######################  LIVE MESSAGES  #####################
# bit ugly, needs simplification?

window.fix_scroll_if_above_init = ->
  window.before_height = document.body.scrollHeight
  window.before_top = document.documentElement.scrollTop
    
window.fix_scroll_if_above = (tar) ->
  after_height = document.body.scrollHeight
  return if !$(tar).length
  
  # console.log "comparing target top of #{$(tar).position().top} to #{before_top}"
  if document.body.scrollHeight != 0 and $(tar).position().top < before_top
    console.log "fixing height, before: #{before_height}, after: #{after_height}, scrolltop: #{$(document).scrollTop()}"
    window.scrollTo(0, before_top+(after_height-before_height) )
    console.log "now height is #{ document.body.scrollHeight}"

# grr, messages from main will be using .unveil?
window.chat_g = (msg) ->
  window.m = msg
  console.log "chat_g", msg
  return if msg.uid in user.ig if user and user.ig and msg.uid and user.ig.length > 0    
  before_height = document.documentElement.scrollHeight
  before_top = document.documentElement.scrollTop
  # console.log "Skipping ignored by #{uid} in #{user.ig.join ','}"
  fix_scroll_if_above_init()
  
  # only add to #msg-new if not #just_more .group_show_id is not visible - complicated, not sure how often it'll be the case?
  tar = $("#just_more .group_show_#{msg.gid}:visible").first() # being tricky -- hard to detect 'inView
  tar = $(".group_show_#{msg.gid}") if !tar.length or !inView(tar)

  # only add to 'just now' if we couldn't find the group chat
  if tar.length
    console.log "adding inside group box for #{msg.title}"
    # tar.show().append(msg.inside_group).find('abbr').timeago().end().parents('.group_base').show()
    tar.show().append(msg.inside_group).parents('.group_base').show() # .find('abbr').timeago().end()
    # window.timefix(tar)

  else if $('#msg-window').is(':visible')
    
    # open it on the messages tab if it's been opened already since they'll show up there
    # need to add 'in group BLAH' to this somehow??  once I get rid of main, need to add 'group msg header'?
    msg.full = "[ #{msg.title} ]<br> #{msg.inside_group}"
    console.log "group box for #{msg.title} not found, adding to just_now"
    console.log msg.full
    msgs_show_new( msg ) #if tar.length # I don't think group messages can show if their group 'box' doesn't exist yet?
  
  preview_from_msg( msg ) if msg.adm and !$('#msg-window:visible').length # show preview if we're a group admin & not in 'just now'?
  group_scroll()
  fix_scroll_if_above(tar)


window.msg_to_chat = ->
  # return
  res = $(body).find('.main').text().replace(/Rate comments/,'')
  
  if !res
    console.log "Unable to find short message for chat-here in ", message
    # short = message.replace(/<(?:.|\n)*?>/gm, '');
    # stupid hack to remove thumb + the body class next to it...
    shrt = body.replace(/body/,'').replace(/<img[^>]+/,'')
    chat_say msg: "<div class='bgap'>&rarr; #{shrt}</div>"
  else
    chat_say msg: "<p>&rarr; #{res}</p>"  

# doing our own before/after vs. using fix_scroll_if_above?
# group messages don't include .full
# move 'keep_scroll_position' as a function with a func you pass in it can 'await'?
window.msgs_show_new = (message) ->
  console.log "showing inside 'just_more'", message
  src = document.querySelector('body.messages') || document.querySelector("#msg-window")
  before = src.scrollHeight
  msg.full = "<div class='just_added'>#{msg.msg}</div>" if !msg.full # just added notifications dont have full
  $("#msg-new").show().find('.messages').append( message.full )
  # $('#msg-new abbr').timeago()
  # window.timefix( $("#msg-new") )
  after = src.scrollHeight

  # if msgs are open, scroll so it doesn't move
  if $("#msg-window").is(':visible') or $('body').hasClass('messages')
    # console.log("msg-window is open", before-after)
    if document.documentElement.scrollTop and before-after < 0
      console.log "we're scrolled down, so fixing scroll for #{after-before}"
      # scroll us equal to the amount of scroll height added
      window.scrollTo(0, document.documentElement.scrollTop + (after-before) )
      
# tempted to just create the profile when sending to faye from the base msg, seems simpler..
# preview_from_msg({prev: "<div id='msg-preview'>test message</div>"})
window.preview_from_msg = (m) ->
  # console.log "simple preview", m
  $('#msg-preview').remove()
  $('body').append m.prev unless $('#msg-window').is('.opened') # don't show preview @ bottom right

window.group_scroll = ->
  $('.group_chat_inner').each ->
    $(@).prop 'scrollTop', $(@).prop('scrollHeight')
  false

# does this work?
window.chat_scroll_down = ->
  $('#chat-here').prop 'scrollTop', $('#chat-here').prop('scrollHeight')+100
  delay 150, -> 
    $('#chat-here').prop 'scrollTop', $('#chat-here').prop('scrollHeight')+100


$.getScript = (url, success, error) ->
  script = document.createElement('script')
  $script = $(script)
  script.src = url
  $('head').append script
  $script.bind 'load', success if success
  $script.bind 'error', error if error
  return

window.sortable = ->
  if window.Sortable then sort2() else $.getScript("/javascripts/Sortable.js", sort2)
  
window.sort2 = ->
  console.log "sort2"
  if !$('.sortable').get(0)
    return console.log("Didn't find object to sortable")

  # stop dragging open the msg window
  window.s = new Sortable $('.sortable').get(0), 
    onStart: -> $('.itm').removeClass('tip'); $('.popover').hide()
    onUpdate: saveOrder
    onEnd: -> 
      $('.itm').addClass('tip')
      try 
        document.removeEventListener 'mousemove', window.msg_open_move
        document.removeEventListener 'touchmove', window.msg_open_move
        document.getElementById('msg-window').style.right = '-9999px'
      catch e
        console.log('sortable caught', e)
    

window.profanity = (node) ->
  # never filter profanity on an 'edit' page?
  return if location.href.match(/edit/) or !node
  if node.nodeType is 3 and node.parentNode.nodeName isnt "SCRIPT"
    if node.nodeValue.match(swearing)
      
      #console.log("matched " + node.nodeValue)
      $("#profanity_msg").show()
      node.nodeValue = node.nodeValue.replace(swearing, "****")
  if node.childNodes?
    i = 0

    while i < node.childNodes.length
      profanity node.childNodes.item(i)
      i++
      
swearing = new RegExp("bastard|\\bass\\b|dick\b|dickwad|cunt|fs+us+cs+k|\btwat|fucking|fucker|fuck|\\bshit|asshole|bitch|pussy|\\bcum\\b|jiz|whore|douchebag|b!tch|faggot|\\bnigger|\\bnigga|slut|\bprick\\b|@ss|dammit", "gi")
swearing.compile

# inline in meta now
# window.fix_house_ad = (ad_name) ->
#   # log "got fix house for '#{ad_name}'"
#   $( document.getElementById(ad_name) ).remove() #replaceWith "<p>#{  adblock_ad() }</p>"


    
window.topics_check = ->
  topic_add_new() if $('.grd-row-col-4-6 .well').length and $('body').hasClass('groupshow')
  topic_mark_seen() if $('body').hasClass('topicshow')  
  
# show 'new' next to topic links that we've visited already
# update link to be 'last' if we've been to this topic before  
window.topic_add_new = ->
  return if $('body').hasClass('added_new') #length # idempotent
  $('body').addClass('added_new')

  $('a.title').each (idx, ele) ->
    e = $(ele)
    href = e.attr("href").replace(/(\d+).*/,"$1") #\ /g,'%20')
    last = localStorage["t_" + href]
    
    # this is NOT working...
    # console.log("got " + last + " vs " + e.data('up') + " for localStorage[\"t_"+href+"\"]")
    if !last or e.data('up') > last
      e.parents('.itm').append "<span class='mynew'>&bull; new</span>"
    
  # if most everything is new, mark nothing. moving to multiplier vs constant #
  # console.log $(".mynew").length*0.9 , " > ", $('ul.group-topics li a').length-1 
  
  # so if they're all new, 20*0.9 > 20-1, that's not right... I want it to be 20 > total*0.9
  $(".mynew").hide() if $(".mynew").length*0.9 > $('ul.group-topics li a').length-1  
  # $(".mynew").hide() if $(".mynew").length > $('ul.group-topics li a').length*0.9
  
  
# on a topic page
window.topic_mark_seen = ->
  #move us to the comment in #hash
  topic_take_to_new()
  
  # show 'new' comments with an outline
  href = location.pathname.replace(/(\d+).*/,"$1") #\ /g,'%20')
  last = localStorage["t_" + href]
  # console.log "last for localStorage[\"t_#{href}\"] = #{last}"
  if last
    $(".comments > div").each (i, e) ->
      # console.log("Comparing " + $(e).data('up') + " vs " +  last)
      if $(e).data('up') and $(e).data('up') > last
        # or remove 
        $(e).addClass( "newer" )
        # $(e).addClass("clearfix") if $(e).height > 35
        #.children('.body').addClass 'clearfix' # ugh

  localStorage["t_" + href] = time_to_i()


# moved from .comment -> ul.comments li
window.topic_take_to_new = ->
  h = document.location.hash.match(/^#last/)
  if h
    $(".comment:last").addClass "newer"
    # do i still need the delay?
    delay 10, ->
      scroll_to ".comments > div:last"
  
  # h = location.hash.match(/^#c(\d+)/)
  h = location.href.match(/c=(\d+)/) # also work with ?c=#, as we now use?... or ONLY? hmm.
  # new replies should almost *always* be on the last page...
  # test this for a bit though
  if h
    # d "scrolling to ul.comments li[data-id=" + h[1] + "]"
    # $(".comments > div[data-id=" + h[1] + "]").addClass("newer").attr('style', 'border: 1px solid #000 !important')
    # delay 10, ->
      # scroll_to ".comments > div[data-id=" + h[1] + "]"

    console.log('scrolling to ' + h[1]);
    # $(window).scrollTop(  $(\".media[data-id='#{@p.c.to_i}']\").css('border', '1px solid #666').offset().top-150 )
    delay 100, ->
      $(window).scrollTop(  $(".media[data-id='#{h[1]}']").css('border', '1px solid #666').offset().top-150 )

