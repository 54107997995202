# ALL STUFF that should be inlined on a particular page

# TODO: move into now_comment
'.continue_to_poem'.live ->
  return if user and user.plan != 'free'
  
  # make a pst to check the current count
  $.get "/comment/comment_required_check", (data) =>
    if (data == 'OK')
      redirect_to(this.href)
    else
      $('.required_info').html(data)
    
  false

# TODO: move into item#edit
'.poem_body textarea'.live 'keyup', ->
  clearTimeout(window.wc) if window.wc
  window.wc = delay 600,  =>
    v = $(@).val()
    num = $(@).val().match(/[\w\-'’‘]+/g) if v and v.length > 5
    $('.word_count').html "#{  num.length  } words" if num
  
# need to hide this menu if we have the 'big' rich text editor open
'.font-menu li a'.live ->
  n = $(@).text()
  nf = n.replace(/\s+/, '_').toLowerCase()
  console.log "setting font to ", n
  $('.rich_editor').attr('class',"form-control rich_editor font_custom font_#{nf} open").css 'font-family', "#{n}"
  $('#item_font').val $(@).text()
  $('.dropdown-menu').hide()
  false

# toggle between center/left
'.font-center'.live ->
  i = $(@).children('i')
  
  if i.is('.fa-align-center') # was center, want left
    $(i).removeClass('fa-align-center').addClass('fa-align-left')
    $('#item_align').val 'Left'
    $('.rich_editor').css 'text-align', 'left'
    $('.center_warn').toggle()
    
  else
    $(i).removeClass('fa-align-left').addClass('fa-align-center')    
    $('#item_align').val 'Center'
    $('.rich_editor').css 'text-align', 'center'
    $('.center_warn').toggle()

#TODO: move to ticketsController, or just remote=true
# ############  TICKETS   ##############
# 'a.owner'.live ->
#   $.post $(@).attr('href'), (data) =>
#     $(@).parents('.ticket').first().find('.owner_current').html data+"<span class='left_bar'>&nbsp;</span>"
#   false

'.ticket.closed h2, .ticket.opened h2'.live ->
  $(@).parents('.ticket').toggleClass('opened, closed')

# what causes the ticket to close? it's closing even if we get a 500 error which makes it easy to lose your reply
# '.ticket-form'.live 'submit', ->
#   console.log "ticket update, redirecting after 3s if no response"
  
#   window.t_delay = delay 3000, -> 
#     console.log "redirecting, didn't get ticket update..."
#     redirect_to location.href
    
#   $.post $(@).attr('action'), $(@).serialize(), (data) ->
#     $('.ticket-form textarea').val('')
#     $('.ticket-form checkbox').attr('checked','')
#     $('.ticket-form').trigger('reinitialize.areYouSure');
#   false
  

# TODO: move to drafts

# ##############################    DRAFTS    ##############################  
window.auto_len = 0
window.last_draft = 0
window.last_metric = 0
window.draft_check = ->
  return if window.last_draft > time_to_i()-16
  return clearInterval(window.draft_check) if !$('#item_notes').length # disable ourselves

  $('#item_draft_random').val( parseInt Math.random()*99999 ) if $('#item_draft_random').val() == '0'
  a = $('#item_notes').val().length + $('#item_rendered').val().length  # moving to JUST textarea checking (notes+body)

  return if a < 40
  save_as_draft() if Math.abs(auto_len - a) > 50 #auto_len is a
  window.auto_len = a
  window.last_draft = time_to_i()
  1
  
".save_draft".live "click", ->
  $('#item_form').removeClass('dirty').trigger('reinitialize.areYouSure')
  save_as_draft true
  false

window.save_as_draft = (clicked) ->
  console.log "saving draft"
  $("#autosave").html wait_gif
  $.post "/draft/add?clicked=#{clicked}", $("#item_form").serialize(), (d) ->
    $("#autosave").html "Saved " + d
    if clicked
      redirect_to '/draft'

  false

# ##############################   STORE  #################################
# update 'total' line in the store
window.update_cost = ->
  tot = 0
  
  if !$("input[name='store_edit']").val()
    v = $("input[name='billing[membership_len]']:checked").val()
    tot += costs["mem" + v]  if parseInt(v,10)
  
  v = $("input[name='billing[points]']:checked").val()
  tot += costs["pts" + v]  if parseInt(v,10)
  
  v = $("input[name='billing[shirt]']:checked").val()
  tot += costs.shirt + costs.ship if v
  tot += 2  if v and v.match(/xx-large/)
  
  v = $("input[name='billing[shirt2]']:checked").val()
  tot += costs.shirt  if v # require 1st checked? ship skip
  tot += 2  if v and v.match(/xx-large/)
  
  book = parseInt $("input[name='billing[book]']").val(), 10
  tot += costs.book + costs.ship if book
  
  # console.log tot
  $("#total_us").text "$" + tot.toFixed(2)


window.show_address = (initial) ->
  book = parseInt($("#billing_book_ink_angels").val(), 10) > 0
  book2 = parseInt($("#billing_book_feeling").val(), 10) > 0
  shirt = $("input[name=billing\\[shirt\\]]:checked").val()
  shirt2 = $("input[name=billing\\[shirt2\\]]:checked").val()
  
  #console.log(book,book2,shirt,shirt2)
  if initial is "initial"
    $("#add_book").click() if book or book2
    $("#add_shirt").click() if (shirt != 'none' && shirt) || (shirt2 != 'none' && shirt2)
  
  if book || book2 || (shirt != 'none' && shirt) || (shirt2 != 'none' && shirt2)
    $("#address").show()
  else
    $("#address").hide()
    

#quiz  
'.quiz_add_a'.live ->
  str = "<textarea class='bbgap closed' name='assignment[quiz][][answers][]' placeholder='Wrong answer' type='text'></textarea>"
  n = $(@).data('n')
  $(@).data('n', n+1)
  $(@).before str.replace(/@/g, n+1)
  false

'.quiz_add_q'.live ->  
  str = "<div class='well question'>   <h2 class='notop'>     Question %   </h2>   <div class='form-group'>     <label class='col-sm-6' for='item_privacy'><textarea class='closed' name='assignment[quiz][][question]' placeholder='Question'></textarea></label>     <div class='col-sm-6 answer_set' >       <textarea class='bbgap closed' name='assignment[quiz][][answers][]' placeholder='Correct answer' type='text'></textarea><textarea class='bbgap closed' name='assignment[quiz][][answers][]' placeholder='Wrong answer' type='text'></textarea><textarea class='bbgap closed' name='assignment[quiz][][answers][]' placeholder='Wrong answer 2' type='text'></textarea><textarea class='bbgap closed' name='assignment[quiz][][answers][]' placeholder='Wrong answer 3' type='text'></textarea><a class='quiz_add_a' href='#' data-n='4'>Add answer</a>     </div>   </div> </div>"
  n = $(@).data('q')
  $(@).data('q', n+1)
  $(@).before str.replace(/%/g, n+1)
  false
  
# move inline
'.group_history'.live ->
  $('.group_chat_inner').html("Loading...").load $(@).attr('href'), ->
    after_ajax()
  false


#touch-hold to show online favs, does this owrk?
# try out touch-hold for tables to show online favs
'a.messages_link'.live 'touchstart', (e) ->
  clearTimeout(window.ml) if window.ml
  window.ml = delay 500, => $('.online_favs_list').show()
  e.preventDefault()    
  false
  
'a.messages_link'.live 'touchend, touchmove touchcancel', ->
  clearTimeout(window.ml) if window.ml