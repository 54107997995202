let debounce = ''

window.timefix = function ( base ) {
  debounce = ''
  try {
    // console.log('timefixing', base)
    base = document.documentElement
    for ( const abbr of base.querySelectorAll( 'abbr:not(.static)' ) ) { //getElementsByTagName( 'abbr' ) ) {
      if ( !abbr.title ) continue;
      //|| abbr.classList.contains( 'static' ) ) continue;
      // console.log( abbr )

      // create time object & attach to element
      if ( !abbr.dateobj ) {
        abbr.dateobj = new Date( abbr.title )
        abbr.title = abbr.dateobj.toLocaleString()
      }

      let mins = ( new Date().getTime() - abbr.dateobj.getTime() ) / 1000 / 60
      let res = ''
      let short = abbr.closest( '.short' )

      // allow # days in the future for contests (ends in 7 days).  toFixed ROUNDS
      if ( mins < -60 * 24 ) { res = 'in ' + ( -mins / 60 / 24 ).toFixed() + ' days' }
      else if ( mins < -8 ) { res = 'in ' + ( -mins / 60 ).toFixed() + ' hours' }
      else if ( mins < 4 ) { res = 'Now' }
      else if ( short && mins < 60 ) { let n = mins.toFixed(); res = `${n} min` }
      else if ( mins < 60 ) { let n = mins.toFixed(); res = `${n} min${n > 1 ? 's' : ''} ago` }
      else if ( short && mins < 60 * 13 ) { let n = ( mins / 60 ).toFixed(); res = `${n} hour` }
      else if ( mins < 60 * 13 ) { let n = ( mins / 60 ).toFixed(); res = `${n} hour${n > 1 ? 's' : ''} ago` }
      else if ( mins < 1440 * 14 ) { let n = ( mins / 1440 ).toFixed(); res = `${n} day${n > 1 ? 's' : ''} ago` }
      else {
        abbr.classList.add( 'static' )
        if ( mins < 60 * 20 ) {
          if ( abbr.dateobj.getHours() == 0 && abbr.dateobj.getMinutes() == 0 ) res = 'at midnight'
          res ||= abbr.dateobj.toLocaleString( 'en-US', { hour: 'numeric', minute: 'numeric' } )
        }
        else if ( mins * 60 * 24 < 6 ) { res = abbr.dateobj.toLocaleString( 'en-US', { weekday: 'short' } ) }
        else if ( abbr.dateobj.getFullYear() != new Date().getFullYear() ) { res = abbr.dateobj.toLocaleString( 'en-US', { year: 'numeric', month: 'short' } ).replace( /202/, "'2" ) }
        else { res = abbr.dateobj.toLocaleString( 'en-US', { month: 'short', day: 'numeric' } ) }
      }

      // console.log('timefixed', abbr.dateobj, res)
      abbr.textContent = res
    }
  } catch ( e ) { console.log( "timefix error", e ) }
}

window.timefix_interval ||= setInterval( window.timefix, 30000 ) // 30s
document.addEventListener( 'turbo:load', () => { window.timefix() } )
document.addEventListener( 'turbo:frame-render', ( e ) => window.timefix( e.target ) )

// their observer looks for each node that matches under & pushes them into a list for manual processing https://github.com/basecamp/local_time/blob/main/lib/assets/javascripts/src/local-time/page_observer.coffee

// then the array of elements to update can be done as a variable and not via querystring each time.
// somehow turbo added things don't trigger this, not sure if we should get rid of it entirely?
if ( MutationObserver ) {
  window.timefix_observer = new MutationObserver( ( mutations ) => {
    let nodes = mutations[0].addedNodes
    // console.log( "mutation on", nodes )
    // TODO: Iterate over nodes and run timefix_element on them and any child 'time' objects

    // skip changes to our already-fixed nodes
    if ( !nodes.length || !nodes[0].parentElement || nodes[0].parentElement.nodeName == 'ABBR' ) return;

    let ele = mutations[0].addedNodes[0].parentElement
    if ( ele && !debounce ) {
      debounce = setTimeout( window.timefix, 100 )
      // console.log( "timefix mutationObserver saw", ele )
    }
  } )
  timefix_observer.observe( document.documentElement, { childList: true, subtree: true } )
}
// document.addEventListener( 'turbo:frame-render', () => {
//   console.log( "frame randered" )
//   window.timefix()
// } )