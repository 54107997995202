import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { ele: String }

  // hide element when we're connected - strange error with it not finding the target, adding timeout & try
  // WTF why would we hide when we connect? This *ONLY* works for my 1/2 assed dropdown menus.
  // works with my current one since the first ele is hidden, but need to fix.
  connect() {
    setTimeout( () => {
      try { q( this.eleValue ).style.display = 'none' } catch ( err ) { console.log( "toggle caught", this.eleValue, err ) }
    }, 50 )
  }

  // wtf this.element is the same for multiple instances?
  // want the 'source of truth' to be the ele
  showhide( event ) {
    // console.log( this.eleValue, this.element )
    // fuck I implemented this to only work with SINGLE objects
    //  || ele.style.display == ''  - had to remove this to hide stuff
    let r = document.querySelectorAll( this.eleValue )
    if ( r.length > 1 ) {
      r.forEach( ele =>
        ( ele.style.display == 'none' ) ? ele.style.display = 'block' : ele.style.display = 'none' )
    } else {
      // var ele = q( this.eleValue );
      var ele = r[0];
      //some js closes it by setting display='' - what js?
      ( ele.style.display == 'none' || ele.style.display == '' ) ? ele.style.display = 'block' : ele.style.display = 'none'

      // remove .active of any siblings (needed for tabs)
      try { this.element.parentNode.querySelector( '.active' ).classList.remove( 'active' ) } catch { }

      // add/remove .active based on the new status (should force, might need !)
      this.element.classList.toggle( 'active', ele.style.display == 'block' )
    }
  }
}