// to reset prompt: localStorage['webpush'] = localStorage['webpushDate']=''
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken } from "firebase/messaging";
async function getFirebaseMessaging() {
  // console.log( 'getting' )
  const firebase = await import( 'https://www.gstatic.com/firebasejs/9.17.2/firebase-app.js' )
  const app = firebase.initializeApp( firebaseConfig );
  const messaging_module = await import( 'https://www.gstatic.com/firebasejs/9.17.2/firebase-messaging.js' )

  console.log( app, messaging_module )
  const messaging = messaging_module.getMessaging();
  console.log( app, messaging )
  return { messaging_module, messaging }
}

const FIREBASE_VAPID_KEY = 'BCyiUlU5K7Uuh0A3nVLGtZcPcrsBy8dYMmfASZb81voBlGwPEmlUT5ZUCmdmA96HpLdfVCjJafXCNY2wufgNEg4'
const firebaseConfig = {
  apiKey: "AIzaSyCovJZHytDEjBOqhgE0-X8ZYaF45DQBIog",
  authDomain: "vuap-firebase.firebaseapp.com",
  projectId: "vuap-firebase",
  storageBucket: "vuap-firebase.appspot.com",
  messagingSenderId: "745277246830",
  appId: "1:745277246830:web:1bef86a9dcd7bda8ca457f",
  measurementId: "G-3QELZJTX9C"
};

if ( window.location.href.match( /next:3001|localhost/ ) ) localStorage['webpush'] = localStorage['webpushDate'] = ''

const getFirebaseToken = async () => {
  console.log( "getFirebaseToken" )
  let { messaging_module, messaging } = await getFirebaseMessaging()
  // const app = initializeApp( firebaseConfig );
  // const messaging = getMessaging();

  try {
    const currentToken = await messaging_module.getToken( messaging, { vapidKey: FIREBASE_VAPID_KEY } );
    if ( !currentToken ) {
      console.log( "This shouldn't happen - No registration token available. Request permission to generate one." );
    } else {
      console.log( 'firebase', currentToken )
      if ( localStorage['webpush'] == currentToken )
        console.log( "skipping already set webpush token" )
      else {
        bring( 'post', "/home/register_push", { body: { token: currentToken, type: 'fireweb' } } )
        localStorage['webpush'] = currentToken
        localStorage['webpushDate'] = new Date().getTime()
        console.log( "registered ", currentToken )
      }
    }
  } catch ( e ) {
    console.log( "An error occurred while retrieving token. ", e );
  }
};

// needs to be in response to a user click
window.requestForToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log( "firebase permission", permission )
    if ( permission === "granted" && !localStorage['webpush'] ) {
      await getFirebaseToken();
    }
  } catch ( error ) {
    console.log( "An error occurred while getting user permission. ", error );
  }
};
window.tokenno = function () {
  localStorage['webpushDate'] = new Date().getTime()
  byid( 'flash' ).innerHTML = `<div class='notice'>No problem!  If you change your mind, visit profile -> edit -> Notifications.</div>`
}

// Safari ioS doesn't support Notification, geesus
window.showTokenAsk = () => {
  try {
    if ( Notification.permission == 'granted' && !localStorage['webpushDate'] ) {
      console.log( "permission but no token, try immediately" )
      getFirebaseToken();
    } else {
      // show request every 30 days?
      let one_day = 24 * 60 * 60 * 1000
      if ( !localStorage['webpushDate'] || parseInt( localStorage['webpushDate'] ) < new Date().getTime() - one_day * 30 )
        byid( 'flash' ).innerHTML += `<div class='notice'>Want us to send push notifications</a> at a new comment or message? <a href='#' onClick='requestForToken()'>Sure</a> &bull; <a href='#' onClick='tokenno()'>No</div>`
    }
  } catch ( error ) {
    console.log( "An error occurred while getting user permission. ", error );
  }
}